<template>
  <div class="laws">
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />
    <div style="background-color: #fafafa">
      <div class="max_width min_width min_height">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff7129"
              >政策匹配</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>

        <!-- 标签
        <div
          class="bgcce relative"
          style="padding: 30px 30px 50px 30px; background-color: #ffddcc"
        >
          <div>
            <div class="flex align_start">
              <div class="fnf size_15 weight marr_30 text_nowrap">
                栏目类别：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="fnf weight size_15 marr_30 laws_font"
                  :class="{ laws_tag: category == '' }"
                  @click="setTag('category')"
                  >全部</span
                >
                <span
                  v-for="item in first"
                  class="fnf size_15 weight marr_30 laws_font"
                  :class="{ laws_tag: category == item.id }"
                  @click="setTag('category', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div class="flex align_start">
              <div class="fnf size_15 weight laws_mar text_nowrap">
                文件级别：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="laws_font fnf weight size_15 laws_mar"
                  :class="{ laws_tag: file_level == '' }"
                  @click="setTag('file_level')"
                  >全部</span
                >
                <span
                  v-for="item in second"
                  class="fnf size_15 weight laws_mar text_nowrap laws_font"
                  :class="{ laws_tag: file_level == item.id }"
                  @click="setTag('file_level', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <!-- <div class="flex align_start">
						<div class="fnf size_15 weight text_nowrap laws_mar">所属区域：</div>
						<div class="text_line1" style="width: calc(100% - 75px);" :class="{'text_wrap': !flag}">
							<span class="laws_font fnf weight size_15 laws_mar text_nowrap"
								:class="{'laws_tag': area == ''}" @click="setTag('area')">全部</span>
							<span v-for="item in third"
								class="fnf size_15 weight laws_mar text_nowrap laws_font"
								:class="{'laws_tag': area == item.id}"
								@click="setTag('area', item.id)">{{item.name}}</span>
						</div>
					</div> -->

        <!--<div class="flex align_start">
              <div class="fnf size_15 weight laws_mar text_nowrap">
                发文部门：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="laws_font fnf weight size_15 laws_mar text_nowrap"
                  :class="{ laws_tag: source == '' }"
                  @click="setTag('source')"
                  >全部</span
                >
                <span
                  v-for="item in four"
                  class="fnf size_15 weight laws_mar text_nowrap laws_font"
                  :class="{ laws_tag: source == item.id }"
                  @click="setTag('source', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div class="flex align_start">
              <div class="fnf size_15 weight laws_mar text_nowrap">
                政策类型：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="laws_font fnf weight size_15 laws_mar text_nowrap"
                  :class="{ laws_tag: policy_type == '' }"
                  @click="setTag('policy_type')"
                  >全部</span
                >
                <span
                  v-for="item in five"
                  class="fnf size_15 weight laws_mar text_nowrap laws_font"
                  :class="{ laws_tag: policy_type == item.id }"
                  @click="setTag('policy_type', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div class="flex align_start">
              <div class="fnf size_15 weight laws_mar text_nowrap">
                行业类型：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="laws_font fnf weight size_15 laws_mar text_nowrap"
                  :class="{ laws_tag: industry == '' }"
                  @click="setTag('industry')"
                  >全部</span
                >
                <span
                  v-for="item in six"
                  class="fnf size_15 weight laws_mar text_nowrap laws_font"
                  :class="{ laws_tag: industry == item.id }"
                  @click="setTag('industry', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div class="flex align_start">
              <div class="fnf size_15 weight laws_mar text_nowrap">
                所属年份：
              </div>
              <div
                class="text_line1"
                style="width: calc(100% - 75px)"
                :class="{ text_wrap: !flag }"
              >
                <span
                  class="laws_font fnf weight size_15 laws_mar text_nowrap"
                  :class="{ laws_tag: year == '' }"
                  @click="setTag('year')"
                  >全部</span
                >
                <span
                  v-for="item in sevent"
                  class="fnf size_15 weight laws_mar text_nowrap laws_font"
                  :class="{ laws_tag: year == item.id }"
                  @click="setTag('year', item.id)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div class="flex align_center mart_20">
              <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
              <div class="width_50 laws_search">
                <el-input placeholder="请输入关键词" v-model="keyword">
                  <i
                    slot="suffix"
                    class="el-icon-search"
                    @click="searchData"
                  ></i>
                </el-input>
              </div>
            </div>

            <i
              class="el-icon-caret-bottom laws_icon"
              v-if="flag"
              @click="flag = !flag"
            ></i>
            <i
              class="el-icon-caret-top laws_icon"
              v-else
              @click="flag = !flag"
            ></i>
          </div>
        </div> -->

        <!-- 列表 -->
        <section class="martb_30">
          <div class="width flex align_start jus_content">
            <div class="bgfff width_calc70 laws_pad">
              <div>
                <div
                  v-for="item in listsItem"
                  @click="toLawsDetails(item.id)"
                  class="padtb_15 laws_btm_bor"
                >
                  <div
                    class="ttt size_15 text_line1 font cursor weight"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </div>

                  <div class="flex align_center jus_content mart_10">
                    <div class="flex align_center">
                      <i class="el-icon-time icon_size"></i>
                      <div class="size_14 eze">{{ item.create_time }}</div>
                    </div>

                    <div class="flex align_center">
                      <i class="el-icon-user icon_size"></i>
                      <div class="size_14 eze">{{ item.click_num }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 分页 -->
              <div class="width flex align_center jus_center padtb_30 relative">
                <app-mypaginations
                  :pagSize="paginations"
                  :allData="policyList"
                  @setHandleCurrent="handleCurrent"
                  @setHandleSize="handleSize"
                  @setPage="setPage"
                  @setSize="setSize"
                />
              </div>

              <el-empty
                description="暂无数据"
                v-if="empty"
                :image-size="250"
              ></el-empty>
            </div>

            <div class="laws_w30">
              <img src="../../assets/image/17.png" class="laws_w30_pic" />
              <img
                src="../../assets/image/18.png"
                class="mart_20 laws_w30_pic"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- 尾部 -->
    <app-footer @phone="getPhone" />
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import MyPaginations from "@/components/MyPaginations/mypaginations.vue";

export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 20, // 1页显示多少条
        page_sizes: [20, 40, 60, 100], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      listsItem: [],
      flag: true,
      // 栏目类别
      category: "",
      // 文件级别
      file_level: "",
      // 所属区域
      area: "",
      // 发文部门
      source: "",
      // 政策类型
      policy_type: "",
      // 行业类型
      industry: "",
      // 所属年份
      year: "",
      keyword: "",
      first: [],
      second: [],
      third: [],
      four: [],
      five: [],
      six: [],
      sevent: [],
      policyList: [],
      // 总数
      count: 0,
      empty: false,
      offsetHeight: 0,
      phone: "",
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-mypaginations": MyPaginations,
  },
  methods: {
    getPhone(phone) {
      this.phone = phone;
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    // 设置标签以及筛选
    setTag(name, id = "") {
      this[name] = id;

      this.paginations.page_index = 1;

      this.policyList = [];

      this.getPolicyList();
    },
    setPage(data) {
      this.paginations.page_index = data;

      this.getPolicyList();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getPolicyList();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.policyList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    searchData() {
      this.paginations.page_index = 1;
      this.policyList = [];

      this.getPolicyList();
    },
    // 政策列表
    getPolicyList() {
      let data = {
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        category: this.category,
        file_level: this.file_level,
        area: this.area,
        source: this.source,
        policy_type: this.policy_type,
        industry: this.industry,
        year: this.year,
        keyword: this.keyword,
      };

      this.$post("/policy_list", data).then((res) => {
        (this.policyList = res.result ? res.result : []),
          (this.count = res.count);

        if (this.paginations.page_index == 1 && this.policyList.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

        this.resetPaginations();
      });
    },
    // 获取分类
    getCategorylist(type) {
      let data = { type };

      this.$get("/category_list", data).then((res) => {
        if (type == 4) {
          // 政策分类
          this.first = res.result ? res.result : [];
        }

        if (type == 5) {
          // 文件级别
          this.second = res.result ? res.result : [];
        }

        if (type == 3) {
          // 所属区域
          this.third = res.result ? res.result : [];
        }

        if (type == 6) {
          // 发文部门
          this.four = res.result ? res.result : [];
        }

        if (type == 7) {
          // 政策类型
          this.five = res.result ? res.result : [];
        }

        if (type == 8) {
          // 行业类型
          this.six = res.result ? res.result : [];
        }

        if (type == 9) {
          // 所属年份
          this.sevent = res.result ? res.result : [];
        }
      });
    },
    // 跳转政策详情
    toLawsDetails(id) {
      this.$router.push({ path: "/lawsdetails", query: { mid: id } });
    },
  },
  created() {
    this.keyword = this.$route.query.search;
  },
  mounted() {
    // type  类型 1热点资讯 2通知公告 3所属区域 4政策分类 5文件级别 6发文部门 7政策类型 8行业类型 9所属年份
    this.getCategorylist(4);
    this.getCategorylist(5);
    this.getCategorylist(3);
    this.getCategorylist(6);
    this.getCategorylist(7);
    this.getCategorylist(8);
    this.getCategorylist(9);
    if (this.$route.query.ids) {
      this.category = this.$route.query.ids;
    }

    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.getPolicyList();
  },
};
</script>

<style scoped>
@import url("./matching.css");

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #ff7129 !important;
}
.sear_search .el-icon-search {
  color: #ff7129 !important;
}
</style>
